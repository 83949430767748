





















































































































































import { defineComponent, ref, reactive, toRaw, computed, watch } from 'vue-demi';
import { Schematic } from '@project/shared';
import PartsTableDoc from '../components/PartsTableDoc.vue';
import SchematicType from './SchematicType.vue';
import SchematicPart from './SchematicPart.vue';
import SchematicsTableEditMenu from './SchematicsTableEditMenu.vue';
import FileUpload from '@/components/FileUpload.vue';
import RequireOnline from '@/components/RequireOnline.vue';
import Confirm from '@/components/Confirm.vue';
import { typesOptions } from '../schematics';
import { useStore } from '@/store';
import { db } from '../AppDb';
import { api } from '@/api';
import { debouncedWatch } from '@vueuse/core';

export default defineComponent({
  components: {
    PartsTableDoc,
    SchematicType,
    SchematicPart,
    SchematicsTableEditMenu,
    FileUpload,
    RequireOnline,
    Confirm,
  },

  props: {
    codeAmos: {
      type: String,
      default: '',
    },
    fullHeader: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['noData'],

  setup(props, { emit }) {
    const mainStore = useStore();

    const headersShort = ref([
      {
        text: 'Ref.',
        value: 'docRef',
      },
      {
        text: 'Type',
        value: 'type',
      },
      {
        text: 'Title',
        value: 'title',
      },
      {
        text: 'Rev.',
        value: 'rev',
      },
      {
        text: 'Doc',
        value: 'documentation',
      },
      {
        text: '',
        value: 'actions',
        align: 'end',
      },
    ]);

    const headers = ref([
      {
        text: 'Related Part(s)',
        value: 'refManufacturer',
        width: '100px',
      },
      {
        text: 'Ref.',
        value: 'docRef',
      },
      {
        text: 'Type',
        value: 'type',
      },
      {
        text: 'Title',
        value: 'title',
      },
      {
        text: 'Rev.',
        value: 'rev',
      },
      {
        text: 'Doc',
        value: 'documentation',
      },
      {
        text: '',
        value: 'actions',
        align: 'end',
      },
    ]);

    const items = ref<Schematic[]>([]);

    const searchValue = ref('');

    const searchFilter = ref('');
    const typeFilter = ref('');

    debouncedWatch(
      searchValue,
      () => {
        searchFilter.value = searchValue.value || '';
      },
      { debounce: 400 },
    );

    function updateItems() {
      db.schematics.toArray().then((value) => {
        items.value = value.filter((item) => {
          if (props.codeAmos && !item.codeAmos?.includes(props.codeAmos)) {
            return false;
          }
          return true;
        });

        if (!items.value.length) {
          emit('noData');
        }
      });
    }

    updateItems();

    watch(
      () => props.codeAmos,
      () => {
        updateItems();
      },
    );

    const filteredItems = computed(() => {
      const search = searchFilter.value.toLowerCase().trim();
      return items.value
        .filter((item) => {
          if (typeFilter.value && item.type !== typeFilter.value) {
            return false;
          }
          return true;
        })
        .filter((item) => {
          if (!search) return true;
          if (
            search &&
            (item.codeAmos.join('|').toLowerCase().includes(search) ||
              item.title?.toLowerCase().includes(search))
          ) {
            return true;
          }
          return false;
        });
    });

    /**
     * Edit stuff
     */
    const isNew = ref(false);
    const isExisting = ref(false);
    const showOtherFields = ref(false);
    const showEditDialog = ref(false);
    const validForm = ref(false);
    const loading = ref(false);
    const formRef = ref<{
      validate(): void;
    } | null>(null);

    const newItem = (codeAmos = '') => ({
      codeAmos: [codeAmos],
      refManufacturer: '',
      docRef: '',
      title: '',
      rev: '',
      type: '',
      documentation: '',
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const values = reactive<Schematic>(newItem());

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function copyFields(src: any, dest: any) {
      for (const key in dest) {
        dest[key] = src[key];
      }
    }

    const rules = {
      docRef: [(v: string) => !!v || 'Doc ref is required'],
      type: [(v: string) => !!v || 'Type is required'],
      title: [(v: string) => !!v || 'Title is required'],
    };

    function onClickEdit(item: Partial<Schematic>) {
      isNew.value = item.docRef ? false : true;
      showOtherFields.value = true;
      if (isNew.value) {
        item = newItem(props.codeAmos);
        showOtherFields.value = false;
      }
      copyFields(item, values);
      showEditDialog.value = true;
    }

    async function onSubmit() {
      const body: Schematic = { ...toRaw(values) };

      loading.value = true;
      try {
        await api.schematics.save(body);

        const index = items.value.findIndex((item) => item.docRef === body.docRef);
        if (index >= 0) {
          items.value.splice(index, 1, body);
        } else {
          items.value.push(body);
        }

        showEditDialog.value = false;
      } finally {
        loading.value = false;
      }
    }

    async function loadExisting() {
      loading.value = true;
      try {
        const item = await db.schematics.get(values.docRef);
        if (item) {
          item.codeAmos.push(props.codeAmos);
          copyFields(item, values);
        }
      } finally {
        loading.value = false;
        showOtherFields.value = true;
      }
    }

    /**
     * File
     */
    const showFileDialog = ref(false);
    const currentFileParent = ref<Schematic>();
    const currentFileName = ref('');
    const fileValue = ref('');

    function onEditFile(item: Schematic) {
      currentFileParent.value = item;
      if (item.documentation) {
        currentFileName.value = fileValue.value = `pdf/${item.documentation}`;
      } else {
        currentFileName.value = `pdf/${item.docRef}.pdf`;
        fileValue.value = '';
      }
      showFileDialog.value = true;
    }

    async function onFileChanged(fileName: string) {
      if (currentFileParent.value) {
        currentFileParent.value.documentation = fileName.replace('pdf/', '');

        const body = toRaw(currentFileParent.value);
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        delete body._id;

        const schematic = await api.schematics.save(body);

        const item = items.value.find((item) => currentFileParent.value?.docRef === item.docRef);
        const index = items.value.findIndex(
          (item) => currentFileParent.value?.docRef === item.docRef,
        );

        if (item) {
          item.documentation = fileName.replace('pdf/', '');
          items.value.splice(index, 1, schematic);
        }
      }
      showFileDialog.value = false;
    }

    /**
     * Delete stuff
     */
    const deleteItem = ref<Schematic>();
    const deleteShow = ref(false);

    function onDeleteItem(item: Schematic) {
      deleteItem.value = item;
      deleteShow.value = true;
    }

    async function onDeleteItemConfirm() {
      if (deleteItem.value) {
        const schematic = deleteItem.value;

        const index = items.value.findIndex((item) => item.docRef === schematic.docRef);
        await api.schematics.delete(schematic.docRef, props.codeAmos);
        if (index >= 0) items.value.splice(index, 1);
        deleteShow.value = false;
      }
    }

    return {
      hasRoles: mainStore.hasRoles,

      headers,
      headersShort,
      searchValue,
      typeFilter,
      filteredItems,
      typesOptions,

      isNew,
      isExisting,
      showOtherFields,
      loadExisting,
      showEditDialog,
      loading,
      formRef,
      rules,
      values,
      onClickEdit,
      onSubmit,
      validForm,

      showFileDialog,
      currentFileParent,
      currentFileName,
      fileValue,
      onEditFile,
      onFileChanged,

      deleteItem,
      deleteShow,
      onDeleteItem,
      onDeleteItemConfirm,
    };
  },
});
