




import { defineComponent, PropType } from '@vue/composition-api';
import { Schematic } from '@project/shared';

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<Schematic>,
      required: true,
    },
  },
});
