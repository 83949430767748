














import { defineComponent, PropType, computed } from '@vue/composition-api';
import { Schematic } from '@project/shared';

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<Schematic>,
      required: true,
    },
  },

  setup(props) {
    const items = computed(() => {
      return (props.item.codeAmos || []).map((codeAmos) => {
        return {
          codeAmos,
          to: {
            name: 'parts',
            params: {
              codeAmos: codeAmos,
            },
          },
        };
      });
    });

    return {
      items,
    };
  },
});
