






import Vue from 'vue';
import { partsDb } from '../PartsDb';

export default Vue.extend({
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    docUrl: '',
  }),

  watch: {
    item: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!value.documentation) {
          this.docUrl = '';
          return;
        }

        partsDb
          .getFile(`pdf/${value.documentation}`)
          .then((file) => {
            if (file) {
              this.docUrl = `/pdfjs/web/viewer.html#file=${URL.createObjectURL(file)}&name=${
                value.documentation
              }`;
            }
          })
          .catch(() => {
            /* Silent error */
          });
      },
    },
  },
});
